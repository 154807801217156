export const baseMetaDescription =
    'ProConnect（プロコネクト）はフリーランスコンサルと企業のダイレクトマッチングプラットフォーム。最低限のサポートで十分なフリーランスのコンサルタント向けに低マージンで案件を紹介。同じ案件で月額報酬が50万円アップした例も。';

export const sapProjectDescription =
    'ProConnect（プロコネクト）はフリーランスコンサル向けの案件紹介サービスです。高単価SAP案件を取り揃えております。コンサルファーム出身者からSIer出身者までSAP経験のある方に、好条件の案件をスピーディーに紹介いたします。';

export const pmoProjectDescription =
    'ProConnect（プロコネクト）はフリーランスコンサル向けの案件紹介サービスです。高単価なPM,PMO案件を取り揃えております。コンサルファーム出身者からSIer出身者までPMO経験のある方に、好条件の案件をスピーディーに紹介いたします。';

export const baseMetaTitleForProjectPage =
    '案件一覧 | フリーコンサル案件紹介 ProConnect（プロコネクト）';
