import { AppProps } from 'next/app';
import UrlProvider from '../provider/urlContext';
import { MantineProvider } from '../provider/mantineProvider';
import { useLoggerForPageTransition } from '../hooks/useLogger';
import '../styles/globals.css';

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps, router }: AppProps) {
    useLoggerForPageTransition();

    return (
        <MantineProvider>
            <UrlProvider>
                <Component {...pageProps} />
            </UrlProvider>
        </MantineProvider>
    );
}
