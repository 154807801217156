import { datadogLogs } from '@datadog/browser-logs';

const config = {
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE as string,
    env: process.env.NODE_ENV as string
};

datadogLogs.init(config);

export const datadog = datadogLogs;
