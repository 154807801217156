import {
    MantineProvider as _MantineProvider,
    MantineProviderProps,
    createTheme
} from '@mantine/core';

const theme = createTheme({
    fontSmoothing: false
});

export const MantineProvider: React.FC<MantineProviderProps> = ({
    children
}) => <_MantineProvider theme={theme}>{children}</_MantineProvider>;
