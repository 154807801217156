import * as sentry from '@sentry/nextjs';

export const handleError = (err: unknown, functionName: string = '') => {
    if (err instanceof Error) {
        sentry.configureScope((scope) => {
            scope.setTag(err.name, functionName);
        });
        sentry.captureException(err);
    }
    if (typeof err === 'string') {
        sentry.captureMessage(err);
    } else {
        sentry.captureEvent({ message: 'Unknown error', extra: { err } });
    }
    console.error(err);
};
