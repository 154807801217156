import { datadog } from './config/datadog';
import { PageTransitionLog } from '../types';
import { handleError } from './error';

export const recordPageTransitionLog = (target: PageTransitionLog): void => {
    try {
        datadog.logger.info('page transition', target);
    } catch (err) {
        handleError(err, 'recordPageTransitionLog');
    }
};

export const recordOnClickSignIn = () => {
    try {
        datadog.logger.info('onClick signIn', { logType: 'onClick signIn' });
    } catch (err) {
        handleError(err, 'recordOnClickSignIn');
    }
};

export const recordOnClickSignUp = () => {
    try {
        datadog.logger.info('onClick signUp', { logType: 'onClick signUp' });
    } catch (err) {
        handleError(err, 'recordOnClickSignUp');
    }
};

export const generatePageTransitionLog = (prevUrl: string, nextUrl: string) => {
    const target: PageTransitionLog = {
        logType: 'pageTransition',
        user: {
            id: '',
            name: '',
            email: ''
        },
        page: {
            fromPath: prevUrl,
            toPath: nextUrl,
            fromRouteName: '',
            toRouteName: ''
        }
    };
    return target;
};
