import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import {
    generatePageTransitionLog,
    recordPageTransitionLog
} from '../modules/log';

export const useLoggerForPageTransition = () => {
    const router = useRouter();
    const prevUrlRef = useRef(router.asPath);
    useEffect(() => {
        const handleRouteChange = (url: string) => {
            const target = generatePageTransitionLog(prevUrlRef.current, url);
            recordPageTransitionLog(target);
            prevUrlRef.current = url;
        };
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);
};
