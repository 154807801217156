import { MasterIconFile } from '@/types/master';
import { ProjectsFilterForm } from '@/types/project';
import { pmoProjectDescription, sapProjectDescription } from '@/modules/config/meta';

export const projectIconList: MasterIconFile[] = [
    {
        masterId: 1,
        alt: '戦略コンサル icon',
        src: '/images/project_logo_1.svg'
    },
    {
        masterId: 2,
        alt: '業務コンサル icon',
        src: '/images/project_logo_2.svg'
    },
    {
        masterId: 3,
        alt: 'ITコンサル icon',
        src: '/images/project_logo_3.svg'
    }
];

export const otherIconFile: MasterIconFile = {
    masterId: 4,
    alt: 'その他 icon',
    src: '/images/project_logo_4.svg'
};

export const officeFrequencySuffix = '出社';
export const atOfficeFrequencyIdsWithSuffix = [30, 40, 50, 60, 70, 80];

export const defaultProject = {
    id: '',
    title: '',
    maxFee: '',
    minFee: '',
    taskStartDate: '',
    taskEndDate: '',
    consultingGenre: null,
    operationMin: '',
    operationMax: '',
    mainWorkArea: '',
    requiredSkills: '',
    constructorTierLevel: null,
    isPlatform: false,
    industryTags: [],
    numberOfApplicants: '',
    hiringSummary: '',
    atOfficeFrequency: null,
    skillTags: []
};

export const initialProjectSearchValues: ProjectsFilterForm = {
    text: '',
    industryTags: [],
    lowerLimitMonthlyRate: null,
    upperLimitMonthlyRate: null,
    lowestPercentageOfOperations: '',
    highestPercentageOfOperations: '',
    startTime: '',
    atOfficeFrequency: '',
    area: '',
    consultingGenre: '',
    page: '1',
    orderBy: 'new'
};

export const projectFilterFormKeys = Object.keys(initialProjectSearchValues);

export const projectFeature = [
    {
        id: 'SAP',
        header: {
            name: 'SAP案件',
            href: 'project/sap'
        },
        meta: sapProjectDescription
    },
    {
        id: 'PMO',
        header: {
            name: 'PMO案件',
            href: 'project/pmo'
        },
        meta: pmoProjectDescription
    }
];
